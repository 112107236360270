body,
.ant-layout {
  background: #f3f3f3;
  color: #27292c;
}

a {
  color: #ef0000;
}

a:hover {
  color: #f04d4d;
}

a.white {
  color: #fff;
}

a.white:hover {
  color: #ef0000;
}

a.dark {
  color: #27292c;
}

a.white:hover {
  color: rgba(39, 41, 44, 0.7);
}

.container {
  width: 1140px;
  margin: 0 auto;
}

.ant-layout-content {
  background: #f3f3f3;
  width: 1140px;
  margin: 0 auto;
  padding: 1rem;
}

.ant-layout-header {
  background: #0b1822;
  height: 48px;
  position: relative;
}

.ant-layout-header .container {
  padding: 0 1rem;
}

.ant-layout-header .logo img {
    height: 36px;
    position: absolute;
    left: 0px;
    top: 5px;
}

.header .ant-menu-horizontal {
  background: none;
  border-bottom: none;
}

.header .ant-menu-horizontal > .ant-menu-item,
.header .ant-menu-horizontal > .ant-menu-submenu {
  color: white;
}

.header .ant-menu-horizontal > .ant-menu-item.ant-menu-item-selected,
.header .ant-menu-horizontal > .ant-menu-submenu.ant-menu-item-selected {
  color: #ef0000;
}

.header .logout {
  position: absolute;
  top: -7px;
  right: 0px;
  height: 48px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

h1,
.h1 {
  font-size: 3.052rem;
  font-weight: 500;
  line-height: 63.4816px;
}

h2,
.h2 {
  font-size: 2.441rem;
  line-height: 50.7728px;
}

h3,
.h3 {
  font-size: 1.953rem;
  line-height: 40.6224px;
}

h4,
.h4 {
  font-size: 1.563rem;
  line-height: 32.5104px;
}

h5,
.h5 {
  font-size: 1rem;
  line-height: 26px;
  font-weight: 500;
}

h6,
.h6 {
  font-size: 0.8rem;
  line-height: 16.64px;
  font-weight: 500;
}

.ant-card,
.ant-table-wrapper {
  border-radius: 0px;
  border: 1px solid #f2f5f7;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}

.ant-table-body {
  background: white;
}

.ant-table-tbody tr:hover,
.ant-table-tbody tr:focus,
.ant-table-tbody tr:active {
}

.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: rgba(239, 0, 0, 0.05) !important;
}

.ant-table tr td img {
  max-width: 100%;
}

.login-page {
  background: #0b1822;
  height: 100vh;
}

.login-page .ant-layout-content {
  background: #0b1822;
}

#login {
  width: 650px;
  margin: 3rem auto 0;
}

#login .ant-card-body {
  min-height: 320px;
}

#edit-profile {
  width: 650px;
  margin: 0 auto 0;
}

.ant-input {
  width: 100% !important;
}

.ant-select {
  width: 100% !important;
}

form label {
  margin: 0 0 0.25rem 0 !important;
  font-weight: bold;
  display: block;
}

form .ant-row {
  margin: 0 0 1rem 0 !important;
}

.text-primary {
  color: #ef0000;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.ant-btn-primary {
  background-color: #ef0000;
  border-color: #ef0000;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  background-color: #ef0000;
  border-color: #ef0000;
  opacity: 0.8;
  color: white;
}

.ant-btn-success {
  background-color: #018e42;
  border-color: #018e42;
  color: white;
}

.ant-btn-success:hover,
.ant-btn-success:focus,
.ant-btn-success:active {
  background-color: #018e42;
  border-color: #018e42;
  opacity: 0.8;
  color: white;
}

.mt-4 {
  margin-top: 0.25rem !important;
}

.mr-4 {
  margin-right: 0.25rem !important;
}

.mb-4 {
  margin-bottom: 0.25rem !important;
}

.ml-4 {
  margin-left: 0.25rem !important;
}

.m-4 {
  margin: 0.25rem !important;
}

.mt-8 {
  margin-top: 0.5rem !important;
}

.mr-8 {
  margin-right: 0.5rem !important;
}

.mb-8 {
  margin-bottom: 0.5rem !important;
}

.ml-8 {
  margin-left: 0.5rem !important;
}

.m-8 {
  margin: 0.5rem !important;
}

.mt-16 {
  margin-top: 1rem !important;
}

.mr-16 {
  margin-right: 1rem !important;
}

.mb-16 {
  margin-bottom: 1rem !important;
}

.ml-16 {
  margin-left: 1rem !important;
}

.m-16 {
  margin: 1rem !important;
}

.mt-24 {
  margin-top: 1.5rem !important;
}

.mr-24 {
  margin-right: 1.5rem !important;
}

.mb-24 {
  margin-bottom: 1.5rem !important;
}

.ml-24 {
  margin-left: 1.5rem !important;
}

.m-24 {
  margin: 1.5rem !important;
}

.mt-32 {
  margin-top: 2rem !important;
}

.mr-32 {
  margin-right: 2rem !important;
}

.mb-32 {
  margin-bottom: 2rem !important;
}

.ml-32 {
  margin-left: 2rem !important;
}

.m-32 {
  margin: 2rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.mr-40 {
  margin-right: 2.5rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.ml-40 {
  margin-left: 2.5rem !important;
}

.m-40 {
  margin: 2.5rem !important;
}

.mt-48 {
  margin-top: 3rem !important;
}

.mr-48 {
  margin-right: 3rem !important;
}

.mb-48 {
  margin-bottom: 3rem !important;
}

.ml-48 {
  margin-left: 3rem !important;
}

.m-48 {
  margin: 3rem !important;
}

.mt-56 {
  margin-top: 3.5rem !important;
}

.mr-56 {
  margin-right: 3.5rem !important;
}

.mb-56 {
  margin-bottom: 3.5rem !important;
}

.ml-56 {
  margin-left: 3.5rem !important;
}

.m-56 {
  margin: 3.5rem !important;
}

.mt-64 {
  margin-top: 4rem !important;
}

.mr-64 {
  margin-right: 4rem !important;
}

.mb-64 {
  margin-bottom: 4rem !important;
}

.ml-64 {
  margin-left: 4rem !important;
}

.m-64 {
  margin: 4rem !important;
}

.pt-4 {
  padding-top: 0.25rem !important;
}

.pr-4 {
  padding-right: 0.25rem !important;
}

.pb-4 {
  padding-bottom: 0.25rem !important;
}

.pl-4 {
  padding-left: 0.25rem !important;
}

.p-4 {
  padding: 0.25rem !important;
}

.pt-8 {
  padding-top: 0.5rem !important;
}

.pr-8 {
  padding-right: 0.5rem !important;
}

.pb-8 {
  padding-bottom: 0.5rem !important;
}

.pl-8 {
  padding-left: 0.5rem !important;
}

.p-8 {
  padding: 0.5rem !important;
}

.pt-16 {
  padding-top: 1rem !important;
}

.pr-16 {
  padding-right: 1rem !important;
}

.pb-16 {
  padding-bottom: 1rem !important;
}

.pl-16 {
  padding-left: 1rem !important;
}

.p-16 {
  padding: 1rem !important;
}

.pt-24 {
  padding-top: 1.5rem !important;
}

.pr-24 {
  padding-right: 1.5rem !important;
}

.pb-24 {
  padding-bottom: 1.5rem !important;
}

.pl-24 {
  padding-left: 1.5rem !important;
}

.p-24 {
  padding: 1.5rem !important;
}

.pt-32 {
  padding-top: 2rem !important;
}

.pr-32 {
  padding-right: 2rem !important;
}

.pb-32 {
  padding-bottom: 2rem !important;
}

.pl-32 {
  padding: 2rem !important;
}

.p-32 {
  padding: 2rem !important;
}

.pt-40 {
  padding-top: 2.5rem !important;
}

.pr-40 {
  padding-right: 2.5rem !important;
}

.pb-40 {
  padding-bottom: 2.5rem !important;
}

.pl-40 {
  padding-left: 2.5rem !important;
}

.p-40 {
  padding: 2.5rem !important;
}

.pt-48 {
  padding-top: 3rem !important;
}

.pr-48 {
  padding-right: 3rem !important;
}

.pb-48 {
  padding-bottom: 3rem !important;
}

.pl-48 {
  padding-left: 3rem !important;
}

.p-48 {
  padding: 3rem !important;
}

.pt-56 {
  padding-top: 3.5rem !important;
}

.pr-56 {
  padding-right: 3.5rem !important;
}

.pb-56 {
  padding-bottom: 3.5rem !important;
}

.pl-56 {
  padding-left: 3.5rem !important;
}

.p-56 {
  padding: 3.5rem !important;
}

.pt-64 {
  padding-top: 4rem !important;
}

.pr-64 {
  padding-right: 4rem !important;
}

.pb-64 {
  padding-bottom: 4rem !important;
}

.pl-64 {
  padding-left: 4rem !important;
}

.p-64 {
  padding: 4rem !important;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #ef0000;
  border-bottom: 2px solid #ef0000;
}

.ant-pagination-item-active a {
  color: #ef0000;
}

.ant-pagination-item-active {
  border-color: #ef0000;
}

.ant-input:focus,
.ant-input:active,
.ant-input:hover {
  border-color: #ef0000;
}

.validation-summary {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
  padding: 1rem;
  margin-bottom: 1rem;
}

.validation-summary ul {
  margin-bottom: 0;
}

.upload-image-wrap {
  background-size: cover;
  background-position: center center;
  max-width: 50%;
  border: 1px solid #ccc;
}

.upload-image-wrap img {
  max-width: 100%;
}

.sponsor-uploader {
  width: 180px;
  height: 180px;
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.image-library-wrapper {
  height: 590px;
  overflow-x: hidden;
  overflow-y: auto;
}

.image-library-wrapper .image-wrapper img {
  cursor: pointer;
  max-width: 100%;
}

.image-library-wrapper .image-wrapper {
  overflow: hidden;
  height: 110px;
}