.personal-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-user-icon {
  background-color: #ef0000;
  color: white;
}

.personal-section {
  display: flex;
  align-items: baseline;
}

.ant-layout-sider {
  background: none;
}

.personal-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 46px;
}
.justify {
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  cursor: pointer;
}
